import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Header/Header'

const ProfileLayout = () => {
  return (
    <>
      <Header />
      <Content>
        <div className="ui fluid container mainContainerWrap">
          <Outlet />
        </div>
      </Content>
    </>
  )
}

export default ProfileLayout