export const API_BASE_URL = process.env.REACT_APP_API_HOST
export const etherScanUrl = process.env.REACT_APP_ETHER_SCAN_URL
export const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
export const secondaryContractAddress =
  process.env.REACT_APP_SECONDARY_CONTRACT_ADDRESS
export const chainId = process.env.REACT_APP_CHAIN_ID
export const PLATFORMFESS = process.env.REACT_APP_PLATFORMFESS
export const AdminWalletAddress = process.env.REACT_APP_ADMINWALLETADDRESS
export const ENCRYPT_KEY = process.env.REACT_APP_ENCRYPT_KEY

export const domain = {
  chainId: process.env.REACT_APP_CHAIN_ID,
  name: "CrossNFT",
  verifyingContract: process.env.REACT_APP_CONTRACT_ADDRESS,
  version: "1",
}
export const EIP712Domain = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "chainId", type: "uint256" },
  { name: "verifyingContract", type: "address" },
]
export const mintVoucher = [
  { name: "tokenId", type: "uint256" },
  { name: "amount", type: "uint256" },
  { name: "royaltyFees", type: "uint96" },
  { name: "royaltyKeeper", type: "address" },
  { name: "nftAddress", type: "address" },
  { name: "nftOwner", type: "address" },
  { name: "tokenUri", type: "string" },
]
export const priListing = [
  { name: "tokenId", type: "uint256" },
  { name: "unitprice", type: "uint256" },
  { name: "countervalue", type: "uint256" },
  { name: "amount", type: "uint256" },
  { name: "nftOwner", type: "address" },
  { name: "listed", type: "bool" },
  { name: "isEth", type: "bool" },
]
export const auctionItemSeller = [
  { name: "royaltyFees", type: "uint256" },
  { name: "tokenId", type: "uint256" },
  { name: "nftBatchAmount", type: "uint256" },
  { name: "minimumBid", type: "uint256" },
  { name: "nftAddress", type: "address" },
  { name: "owner", type: "address" },
  { name: "royaltyKeeper", type: "address" },
  { name: "tokenURI", type: "string" },
  { name: "isEth", type: "bool" },
]
export const auctionItemBuyer = [
  { name: "tokenId", type: "uint256" },
  { name: "nftBatchAmount", type: "uint256" },
  { name: "pricePaid", type: "uint256" },
  { name: "nftAddress", type: "address" },
  { name: "buyer", type: "address" },
  { name: "tokenURI", type: "string" },
]

export const whiteLogo =
  "https://stage-crossnft.s3.us-east-2.amazonaws.com/user_images/0efb1ea0-7342-44cd-98f7-bbe378e3aa2d.svg"
export const blackLogo =
  "https://stage-crossnft.s3.us-east-2.amazonaws.com/user_images/4e50b882-a462-42da-aa54-b69e10e5d81c.png"

export const authToken = () => {
  return localStorage.getItem("authorizationToken")
}
