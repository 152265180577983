
import { combineReducers } from "redux";
import user from "./Reducers/user";

const createRootReducer = () =>
  combineReducers({
    user,
  });

export default createRootReducer;
