import { Content } from "antd/lib/layout/layout";
import React from "react";
import "./PrivateLayout.scss";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";

const PrivateLayout = () => {
  return (
    <>
      <Header />
      <Content>
        <div className="ui container mainContainerWrap">
          <Outlet />
        </div>
      </Content>
    </>
  );
};

export default PrivateLayout;
