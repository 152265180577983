import React from "react";
import "./loader.scss";
import {
  MovingCircleIcon,
} from "../../../assets/images/svg/SvgImages";

const Loader = ({ active }) => {
  return (
    <>
      {active && (
        <div className="loaderWrap">
          <div className="collection__frame">
            <div className="collection__frame__list">
              <div className="collection__frame__list__svgpath collection__frame__list__view">
                <MovingCircleIcon />
                <div className="search_content">
                  <button>Loading...</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
