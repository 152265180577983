import React from "react"
import { Button, Spin } from "antd"
import "./FvButtonStyle.scss"
import { LoadingOutlined } from "@ant-design/icons"
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
)

const FvButton = (props) => {
  return (
    <Button {...props} type="primary">
      {!props.loading && props.text}
    </Button>
  )
}

export default FvButton
