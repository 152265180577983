import React, { useEffect, useState } from "react";
import { Grid, Menu, Row, Col } from "antd";
import "./HeaderStyle.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setDrawerVisibility,
  setIsLoggin,
  setThemeInfo,
  setTokenExpires,
} from "../../../Redux/Reducers/user";
import lightthemeIcon from "../../../assets/images/lightthemeIcon.svg";
import darkthemeIcon from "../../../assets/images/darkthemeIcon.svg";
import FvButton from "../../ui/button/FvButton";
// import { set } from "immer/dist/internal";
const HeaderItem = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themeInfo = useSelector((state) => state?.user?.themeInfo);
  const roles_permission = useSelector(
    (state) => state?.user?.roles_permission
  );
  // console.log(roles_permission, "roles_permission", "hhhh");
  const { useBreakpoint } = Grid;
  const { xl } = useBreakpoint();
  const [role, setRole] = useState("");
  // console.log(role, "role", "hhhh");
  const tokenExpired = useSelector((state) => state?.user?.tokenExpires);
  const isLogin = useSelector((state) => state?.user?.isLoggin);
  const roleType = useSelector((state) => state?.user?.adminRole);
  // console.log(roleType, "roleType", "hhhh");

  useEffect(() => {
    if (roles_permission[0]?.role === "VIEWER") {
      setRole("VIEWER");
    } else if (roles_permission[1]?.role === "VIEWER") {
      setRole("ADMIN");
    } else {
      setRole("MAINTAINER");
    }
  }, [roles_permission]);

  useEffect(() => {
    if (themeInfo === "dark") {
      document.body.classList.remove("lightTheme");
      return document.body.classList.add("darkTheme");
    }
    document.body.classList.remove("darkTheme");
    document.body.classList.add("lightTheme");
  }, []);

  async function toggleTheme() {
    if (themeInfo === "dark") {
      document.body.classList.remove("darkTheme");
      document.body.classList.add("lightTheme");
      dispatch(setThemeInfo("light"));
    } else {
      document.body.classList.remove("lightTheme");
      document.body.classList.add("darkTheme");
      dispatch(setThemeInfo("dark"));
    }
  }
  const drawerClose = () => {
    dispatch(setDrawerVisibility(false));
  };

  const logout = () => {
    localStorage.clear();
    // dispatch(setAccessToken(""));
    dispatch(setIsLoggin(false));
    navigate("/");
  };

  useEffect(() => {
    if (tokenExpired) {
      dispatch(setTokenExpires(false));
      isLogin && logout();
    }
  }, [tokenExpired]);

  return (
    <Row className={`fan-header-sub ${className}`}>
      <Col>
        <Menu
          defaultSelectedKeys={["mail"]}
          mode={xl ? "horizontal" : "inline"}>
          {/* mode="horizontal" */}
          <Menu.Item key="home">
            <NavLink to="/dashboard" onClick={drawerClose}>
              Dashboard
            </NavLink>
          </Menu.Item>
          <Menu.SubMenu key="allCollections" title="Collections">
            <Menu.Item key="collections">
              <NavLink to="/collections" onClick={drawerClose}>
                Collections
              </NavLink>
            </Menu.Item>
            <Menu.Item key="crossNftExclusive">
              <NavLink to="/crossNftExclusive" onClick={drawerClose}>
                CrossNFT Exclusive
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
          {roleType === "1" && (
            <Menu.SubMenu key="create" title="Create">
              <Menu.Item key="addEditCollection">
                <NavLink to="/addCollection" onClick={drawerClose}>
                  Collection
                </NavLink>
              </Menu.Item>
              <Menu.Item key="addEditNft">
                <NavLink to="/addEditNft" onClick={drawerClose}>
                  NFT
                </NavLink>
              </Menu.Item>
            </Menu.SubMenu>
          )}
          <Menu.Item key="users_creators">
            <NavLink to="/users_creators" onClick={drawerClose}>
              Users and Creators
            </NavLink>
          </Menu.Item>
          <Menu.Item key="listing_orders">
            <NavLink to="/listing_orders" onClick={drawerClose}>
              Listing Nft's
            </NavLink>
          </Menu.Item>
          <Menu.Item key="Settings">
            <NavLink to="/Settings" onClick={drawerClose}>
              Settings
            </NavLink>
          </Menu.Item>

          <Menu.Item key="Support">
            <NavLink to="/support" onClick={drawerClose}>
              Support
            </NavLink>
          </Menu.Item>
        </Menu>
      </Col>
      <Col>
        {/* <div className="TwoBtnFix">
          <button onClick={toggleTheme} className={`themeButtonStyle`}>
            <img className="lightthemeIcon" src={lightthemeIcon} alt="light" />
            <img className="darkthemeIcon" src={darkthemeIcon} alt="light" />
          </button>
        </div> */}
        <Col className="btn-add-wrap loginWrap">
          <FvButton
            text="Logout"
            className={"changeStyle"}
            onClick={() => logout()}
          />
        </Col>
      </Col>
    </Row>
  );
};

export default HeaderItem;
