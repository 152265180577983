import React, { Suspense, useEffect, useState } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { axiosRequest, axiosResponse } from "./components/layout/Axios/Axios"
import Loader from "./components/layout/Loader/Loader"
import { useDispatch, useSelector } from "react-redux"
import PrivateLayout from "./components/layout/PrivateLayout/PrivateLayout"
import ProfileLayout from "./components/layout/ProfileLayout/ProfileLayout"

const Login = React.lazy(() => import("./components/pages/Login/Login"))
const Dashboard = React.lazy(() =>
  import("./components/pages/Dashboard/Dashboard")
)
const Collections = React.lazy(() =>
  import("./components/pages/Collections/Collections")
)
const UsersCreators = React.lazy(() =>
  import("./components/pages/UsersCreators/UsersCreators")
)
const CreatorTab = React.lazy(() =>
  import("./components/pages/UsersCreators/TabsData/CreatorTab")
)
const ListingOrders = React.lazy(() =>
  import("./components/pages/ListingOrders/ListingOrders")
)
const Banners = React.lazy(() => import("./components/pages/Banners/Banners"))
const Categories = React.lazy(() =>
  import("./components/pages/Categories/Categories")
)
const Settings = React.lazy(() =>
  import("./components/pages/Settings/Settings")
)
const AddCollection = React.lazy(() =>
  import("./components/pages/AddEditCollection/AddCollection")
)
const EditCollection = React.lazy(() =>
  import("./components/pages/AddEditCollection/EditCollection")
)
const AddEditUser = React.lazy(() =>
  import("./components/pages/AddEditUser/AddEditUser")
)
const FanVerseExclusive = React.lazy(() =>
  import("./components/pages/Collections/FanVerseExclusive")
)
const AddEditNft = React.lazy(() =>
  import("./components/pages/AddEditNft/AddEditNft")
)
const CollectionDetails = React.lazy(() =>
  import("./components/pages/CollectionDetail/CollectionDetails")
)

const PageNotFound = React.lazy(() =>
  import("./components/pages/pageNotFound/PageNotFound")
)
const NftDetails = React.lazy(() =>
  import("./components/pages/NftDetails/NftDetails")
)
const ListforSale = React.lazy(() =>
  import("./components/pages/ListforSale/ListforSale")
)
const Support = React.lazy(() => import("./components/pages/Support/Support"))
const TicketResolved = React.lazy(() =>
  import("./components/pages/Support/TicketResolved")
)

function Routers() {
  const [active, setActive] = useState(false)
  const isLogin = useSelector((state) => state.user.isLoggin)
  const roles_permission = useSelector((state) => state?.user?.roles_permission)
  const [role, setRole] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    axiosResponse(setActive, dispatch)
    axiosRequest(setActive)
  }, [])

  useEffect(() => {
    if (roles_permission[0]?.role === "VIEWER") {
      setRole("VIEWER")
    } else if (roles_permission[1]?.role === "VIEWER") {
      setRole("ADMIN")
    } else {
      setRole("MAINTAINER")
    }
  }, [roles_permission])

  return (
    <>
      <div className="MainLayout">
        <Loader active={active} />
        <Suspense fallback={<Loader active={true} />}>
          <Routes>
            <Route
              path="/"
              element={isLogin ? <Navigate to="/dashboard" /> : <Login />}
            />
            <Route path="/" element={<PrivateLayout />}>
              <Route
                path="/dashboard"
                element={isLogin ? <Dashboard /> : <Navigate to="/" />}
              />
              <Route
                path="/crossNftExclusive"
                element={isLogin ? <FanVerseExclusive /> : <Navigate to="/" />}
              />
              <Route
                path="/collections"
                element={isLogin ? <Collections /> : <Navigate to="/" />}
              />
              <Route
                path="/nft/:id"
                element={
                  isLogin ? (
                    <NftDetails setActive={setActive} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="list-for-sale/:id"
                element={<ListforSale setActive={setActive} />}
              />
              {role === "ADMIN" && (
                <>
                  <Route
                    path="/addCollection"
                    element={isLogin ? <AddCollection /> : <Navigate to="/" />}
                  />
                  <Route
                    path="/editCollection/:id"
                    element={isLogin ? <EditCollection /> : <Navigate to="/" />}
                  />
                </>
              )}

              <Route
                path="/addEditUser"
                element={isLogin ? <AddEditUser /> : <Navigate to="/" />}
              >
                <Route
                  path=":id"
                  element={isLogin ? <AddEditUser /> : <Navigate to="/" />}
                />
              </Route>
              {role === "ADMIN" ? (
                <Route
                  path="/addEditNft"
                  element={isLogin ? <AddEditNft /> : <Navigate to="/" />}
                />
              ) : (
                <></>
              )}

              <Route
                path="/users_creators"
                element={isLogin ? <UsersCreators /> : <Navigate to="/" />}
              />
              <Route
                path="/listing_orders"
                element={isLogin ? <ListingOrders /> : <Navigate to="/" />}
              />
              <Route
                path="/banners"
                element={isLogin ? <Banners /> : <Navigate to="/" />}
              />
              <Route
                path="/categories"
                element={isLogin ? <Categories /> : <Navigate to="/" />}
              />
              <Route
                path="/settings"
                element={
                  isLogin ? (
                    <Settings setActive={setActive} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/support"
                element={isLogin ? <Support /> : <Navigate to="/" />}
              />
              <Route
                path="ticketresolved/:id"
                element={isLogin ? <TicketResolved /> : <Navigate to="/" />}
              />
            </Route>

            <Route path="/collection_info" element={<ProfileLayout />}>
              <Route
                path=":id"
                element={isLogin ? <CollectionDetails /> : <Navigate to="/" />}
              />
            </Route>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/creator_tab" element={<CreatorTab />} />
          </Routes>
        </Suspense>
      </div>
    </>
  )
}

export default Routers
