import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    themeInfo: "dark",
    drawerVisibility: false,
    isLoggin: false,
    is_2FA: false,
    is_email_2FA: false,
    roles_permission: [],
    tokenExpires:false,
    adminRole:""
  },
  reducers: {
    setThemeInfo: (state, action) => {
      state.themeInfo = action.payload;
    },
    setDrawerVisibility: (state, action) => {
      state.drawerVisibility = action.payload;
    },
    setIsLoggin: (state, action) => {
      state.isLoggin = action.payload;
    },
    setIs2FA: (state, action) => {
      state.is_2FA = action.payload;
    },
    setIsEmail2FA: (state, action) => {
      state.is_email_2FA = action.payload;
    },
    setRolesPermission: (state, action) => {
      state.roles_permission = action.payload;
    },
    setTokenExpires: (state, action) => {
      state.tokenExpires = action.payload;
    },
    setAdminRole:(state, action)=>{
      state.adminRole=action.payload;
    }
    
  },
});

export const {
  setThemeInfo,
  setDrawerVisibility,
  setIsLoggin,
  setIs2FA,
  setIsEmail2FA,
  setRolesPermission,
  setTokenExpires,
  setAdminRole,
} = userSlice.actions;
export default userSlice.reducer;
