import ReactDOM from 'react-dom/client';
import "react-loading-skeleton/dist/skeleton.css"
import "antd/dist/antd.min.css"
import "./index.scss"
import Routers from "./Routers"
import { BrowserRouter } from "react-router-dom"
import { store } from "./Redux/Store"
import persistStore from "redux-persist/es/persistStore"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { Toaster } from "react-hot-toast"

const root = ReactDOM.createRoot(document.getElementById('root'));
const persistor = persistStore(store)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Toaster position="top-right" />
        <Routers />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);