import React, { useState } from "react";
import { Layout, Drawer } from "antd";
import { ReactComponent as Logo } from "../../../assets/images/fv_logo.svg";
import "./HeaderStyle.scss";
import {
  MenuIcon,
} from "../../../assets/images/svg/SvgImages";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setDrawerVisibility,
} from "../../../Redux/Reducers/user";
import HeaderItem from "./HeaderItem";

const Header = () => {
  const { Header } = Layout;
  const dispatch = useDispatch();
  const drawerVisibility = useSelector(
    (state) => state?.user?.drawerVisibility
  );
  const [placement, setPlacement] = useState("right");

  const showDrawer = () => {
    dispatch(setDrawerVisibility(true));
  };
  const onClose = () => {
    dispatch(setDrawerVisibility(false));
  };

  return (
    <Header className="fan-header">
      <Link to="/" className="fan-logo">
        <Logo className="fanverse_logo" />
      </Link>
      <HeaderItem className="hideMob" />
      <Drawer
        placement={placement}
        closable={true}
        onClose={onClose}
        open={drawerVisibility}
        className="header--drawer"
      >
        <HeaderItem />
      </Drawer>
      <button onClick={showDrawer} className="menu_toggle btnOnlyForMobile">
        <MenuIcon />
      </button>
    </Header>
  );
};

export default Header;
